
import {Component, Vue} from 'vue-property-decorator'
import MainPage from "@/components/app/MainPage.vue";
import Alert from "germanium-vue-patternfly/src/Alert.vue"

@Component({
  components: {MainPage, Alert}
})
export default class Playground extends Vue {
}
